@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Regular.f51b022d.woff2") format("woff2"), url("Montserrat-Regular.ce08015d.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Bold.fb26b2c3.woff2") format("woff2"), url("Montserrat-Bold.e1bc25fa.woff") format("woff");
  font-weight: bold;
}

body {
  margin: 0;
}

.maintenance-page {
  height: 100vh;
  min-width: 800px;
  min-height: 705px;
  background: #fff;
  display: flex;
  position: relative;
}

.left-block {
  box-sizing: border-box;
  height: 100%;
  background-image: url("bg.042a6605.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: auto;
  overflow: hidden;
}

@media (max-width: 1271px) {
  .left-block {
    display: none;
  }
}

.right-block {
  width: 941px;
  box-sizing: border-box;
  height: 100%;
  padding-top: 45px;
  padding-bottom: 74px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .right-block {
    width: calc(35.7813vw + 25px);
    padding-bottom: calc(2.65625vw + 6px);
  }
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .right-block {
    width: calc(53.4375vw - 314px);
    padding-bottom: calc(3.125vw - 3px);
  }
}

@media (min-width: 1366px) and (max-width: 1600px) {
  .right-block {
    width: calc(8.97436vw + 397.41px);
    padding-bottom: calc(5.55556vw - 41.8889px);
  }
}

@media (min-width: 1271px) and (max-width: 1366px) {
  .right-block {
    width: calc(76.8421vw - 529.663px);
  }
}

@media (max-width: 1365px) {
  .right-block {
    padding-bottom: 47px;
  }
}

@media (max-width: 1271px) {
  .right-block {
    width: 100%;
    padding-bottom: 34px;
  }
}

@media (max-height: 900px) {
  .right-block {
    padding-bottom: 47px;
  }
}

@media (max-height: 768px) {
  .right-block {
    padding-bottom: 34px;
  }
}

.content-layout {
  margin-left: 64px;
  margin-right: 64px;
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .content-layout {
    margin-left: calc(2.34375vw + 4px);
    margin-right: calc(2.34375vw + 4px);
  }
}

@media (max-width: 1920px) {
  .content-layout {
    margin-left: 48px;
    margin-right: 48px;
  }
}

@media (max-width: 1271px) {
  .content-layout {
    margin-left: 52px;
    margin-right: 52px;
  }
}

.title {
  color: #141519;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: bold;
  line-height: 41px;
}

@media (max-width: 1600px) {
  .title {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 1271px) {
  .title {
    text-align: center;
  }
}

.description {
  color: #141519;
  margin-top: 19px;
  font-family: Montserrat;
  font-size: 18px;
  line-height: 28px;
}

.text-content {
  min-height: 578px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-height: 1080px) and (max-height: 1440px) {
  .text-content {
    min-height: calc(703px - 20.8333vh);
  }
}

@media (min-height: 900px) and (max-height: 1080px) {
  .text-content {
    min-height: calc(520px - 3.88889vh);
  }
}

@media (min-height: 768px) and (max-height: 900px) {
  .text-content {
    min-height: calc(76.5152vh - 203.636px);
  }
}

@media (max-height: 768px) {
  .text-content {
    min-height: 384px;
  }
}

@media (min-width: 1271px) and (max-width: 1920px) {
  .text-content {
    font-size: calc(.308166vw + 10.0832px);
  }
}

@media (max-width: 1271px) {
  .text-content {
    min-height: 450px;
    text-align: center;
    font-size: 14px;
    left: 0;
    right: 0;
  }
}

.logo img {
  height: 36px;
}

/*# sourceMappingURL=index.74884a16.css.map */
