@font-face {
  font-family: "Montserrat";
  src:
    url("./Montserrat-Regular.woff2") format("woff2"),
    url("./Montserrat-Regular.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  src:
    url("./Montserrat-Bold.woff2") format("woff2"),
    url("./Montserrat-Bold.woff") format("woff");
  font-weight: bold;
}

body {
  margin: 0;
}

.maintenance-page {
  position: relative;
  display: flex;
  height: 100vh;
  background: white;
  min-width: 800px;
  min-height: 705px;
}

.left-block {
  box-sizing: border-box;
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/bg.jpg');
}

@media (max-width: 1271px) {
  .left-block {
    display: none;
  }
}

.right-block {
  width: 941px;
  padding-top: 45px;
  padding-bottom: 74px;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .right-block {
    padding-bottom: calc(57px + (74 - 57) * (100vw - 1920px) / (2560 - 1920));
    width: calc(712px + (941 - 712) * (100vw - 1920px) / (2560 - 1920));
  }
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .right-block {
    width: calc(541px + (712 - 541) * (100vw - 1600px) / (1920 - 1600));
    padding-bottom: calc(47px + (57 - 47) * (100vw - 1600px) / (1920 - 1600));
  }
}

@media (min-width: 1366px) and (max-width: 1600px) {
  .right-block {
    width: calc(520px + (541 - 520) * (100vw - 1366px) / (1600 - 1366));
    padding-bottom: calc(34px + (47 - 34) * (100vw - 1366px) / (1600 - 1366));
  }
}

@media (min-width: 1271px) and (max-width: 1366px) {
  .right-block {
    width: calc(447px + (520 - 447) * (100vw - 1271px) / (1366 - 1271));
  }
}

@media (max-width: 1365px) {
  .right-block {
    padding-bottom: 47px;
  }
}

@media (max-width: 1271px) {
  .right-block {
    padding-bottom: 34px;
    width: 100%;
  }
}

@media (max-height: 900px) {
  .right-block {
    padding-bottom: 47px;
  }
}
@media (max-height: 768px) {
  .right-block {
    padding-bottom: 34px;
  }
}

.content-layout {
  margin-left: 64px;
  margin-right: 64px;
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .content-layout {
    margin-left: calc(49px + (64 - 49) * (100vw - 1920px) / (2560 - 1920));
    margin-right: calc(49px + (64 - 49) * (100vw - 1920px) / (2560 - 1920));
  }
}

@media (max-width: 1920px) {
  .content-layout {
    margin-left: 48px;
    margin-right: 48px;
  }
}

@media (max-width: 1271px) {
  .content-layout {
    margin-left: 52px;
    margin-right: 52px;
  }
}

.title {
  font-family: 'Montserrat';
  font-weight: bold;
  color: #141519;
  font-size: 34px;
  line-height: 41px;
}

@media (max-width: 1600px) {
  .title {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 1271px) {
  .title {
    text-align: center;
  }
}

.description {
  margin-top: 19px;
  font-family: 'Montserrat';
  font-size: 18px;
  line-height: 28px;
  color: #141519;
}

.text-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  min-height: 578px;
}

@media (min-height: 1080px) and (max-height: 1440px) {
  .text-content {
    min-height: calc(478px + (578 - 478) * (100vh - 1080px) / (1440 - 1920));
  }
}
@media (min-height: 900px) and (max-height: 1080px) {
  .text-content {
    min-height: calc(485px + (478 - 485) * (100vh - 900px) / (1080 - 900));
  }
}
@media (min-height: 768px) and (max-height: 900px) {
  .text-content {
    min-height: calc(384px + (485 - 384) * (100vh - 768px) / (900 - 768));
  }
}
@media (max-height: 768px) {
  .text-content {
    min-height: 384px;
  }
}

@media (min-width: 1271px) and (max-width: 1920px) {
  .text-content {
    font-size: calc(14px + (16 - 14) * (100vw - 1271px) / (1920 - 1271));
  }
}

@media (max-width: 1271px) {
  .text-content {
    left: 0;
    right: 0;
    min-height: 450px;
    font-size: 14px;
    text-align: center;
  }
}


.logo img {
  height: 36px;
}
